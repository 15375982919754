import { useEffect } from "react";
import { Grid } from "../../components/Grid/Grid";
import { Hero } from "../../components/Hero/Hero";
import { HeroNarrow } from "../../components/HeroNarrow/HeroNarrow";
import { Carousel } from '../../components/Carousel/Carousel';
import { Helmet } from "react-helmet";

export function Home() {

    const header = `Don't Delay, Find Yer Dream Job Today!`;
    const headerBody = `Find jobs near you and get yerself sorted wi' Bronte Jobs. It's a doddle to apply!`;
    const headerLink = '/search';
    const headerLinkText = 'Have a Gander';

    const gridHeader = 'Find Your Dream Job in West Yorkshire with Bronte Jobs';
    const gridBody = `Don't faff about! Find yer dream job in West Yorkshire wi' Bronte Jobs. We've got loads o' jobs, so you're bound to find summat that's reet up yer street.`;

    return (
        <>
        <Helmet>
        <title>Bronte Jobs: Find Jobs in West Yorkshire | Keighley and Surrounding Areas Job Board</title>
        <meta name="description" content="Find your next job in West Yorkshire with Bronte Jobs. Browse the latest vacancies, full-time and part-time, from top employers in Keighley, Bradford, and beyond.  Start your job search today!"/> 
        </Helmet>
            <Hero isHome={true}>
                <Carousel></Carousel>
            </Hero>
            <Grid header={gridHeader} body={gridBody}></Grid>
            <HeroNarrow header={header} body={headerBody} link={headerLink} linkText={headerLinkText} align={'centre'}></HeroNarrow>
        </>
    )
}