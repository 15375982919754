import { useContext } from 'react';
import { SearchContext } from '../../providers/SearchProvider';
import './availableJobs.scss';
import { ResultsContext } from '../../providers/ResultsProvider';
import { Link } from 'react-router-dom';
import { Loader } from '../Loader/Loader';

export function AvailableJobs({ loaderOnly }) {

    const { searchResults } = useContext(SearchContext);
    const { isLoading, handleKeywordChange } = useContext(ResultsContext);

    return (


        <section className={`availableJobs availableJobs--${isLoading} availableJobs__loader--${loaderOnly}`}>

            {
                (!isLoading && !loaderOnly && searchResults.length <= 0) &&
                <><h2>1000+ Available jobs</h2><p>Browse through our extensive list of job opportunities in various industries local to you.<br /><br />Or just check out jobs by the top indisturies below</p>
                    <section className='ideas'>
                        <Link onClick={() => { handleKeywordChange('healthcare') }} to={{
                            pathname: '/search',
                            search: '?keywords=healthcare'
                        }}>Heathcare</Link>
                        <Link onClick={() => { handleKeywordChange('warehouse') }} to={{
                            pathname: '/search',
                            search: '?keywords=warehouse'
                        }}>Warehouse</Link>
                        <Link onClick={() => { handleKeywordChange('technology') }} to={{
                            pathname: '/search',
                            search: '?keywords=technology'
                        }}>Technology</Link>
                        <Link onClick={() => { handleKeywordChange('education') }} to={{
                            pathname: '/search',
                            search: '?keywords=education'
                        }}>Education</Link>
                        <Link onClick={() => { handleKeywordChange('logistics') }} to={{
                            pathname: '/search',
                            search: '?keywords=logistics'
                        }}>Logistics</Link>
                        <Link onClick={() => { handleKeywordChange('cleaning') }} to={{
                            pathname: '/search',
                            search: '?keywords=cleaning'
                        }}>Cleaning</Link>
                        <Link onClick={() => { handleKeywordChange('transport') }} to={{
                            pathname: '/search',
                            search: '?keywords=transport'
                        }}>Transport</Link>
                        <Link onClick={() => { handleKeywordChange('customer service') }} to={{
                            pathname: '/search',
                            search: '?keywords=customer service'
                        }}>Customer Service</Link>

                    </section>
                </>
            }
            {(isLoading) &&
                <Loader></Loader>
            }
        </section>

    )
}