import { Link } from "react-router-dom";
import './ContactForm.scss'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useState } from "react";
import { Loader } from "../Loader/Loader";


const schema = yup.object({
    name: yup.string().required('Name is required'),
    emailAddress: yup.string().email('Invalid email format').required('Email is required'),
    message: yup.string().required('Your messages is required'),
    confirmed: yup.boolean().oneOf([true], 'You must agree to the terms and conditions'),
}).required();


export function ContactForm() {

    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });



    const onSubmit = async (body) => {

        delete body.confirmed;
        setIsLoading(true);

        await fetch('https://api.brontejobs.com/api/contact', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                reset();
                setHasSubmitted(true);
                setIsLoading(false);
                return response.json();
            })
            .then(data => {
                console.log('Success:', data);
            })
            .catch(error => {
                setIsLoading(false);
                console.error('Error:',
                    error);
            });
    }

    return (
        <section className="contactform">

            {
                isLoading &&
                <div className="loader-wrapper">
                    <Loader></Loader>
                </div>
            }
            {
                !isLoading && !hasSubmitted &&
                <>
                    <h2>Get in touch</h2>
                    <p>Have a question or need assistance? Contact us!</p>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <label className={`input ${errors?.name?.message && 'label-error'}`}>
                            <span>Name</span>
                            <input type="text" id="name" {...register('name')} />
                            {errors.name && <span className="error">{errors.name.message}</span>}
                        </label>
                        <label className={`input ${errors?.emailAddress?.message && 'label-error'}`}>
                            <span>Email</span>
                            <input type="text" id="emailAddress" {...register('emailAddress')} />
                            {errors.emailAddress && <span className="error">{errors.emailAddress.message}</span>}
                        </label>
                        <label className={`input ${errors?.message?.message && 'label-error'}`}>
                            <span>Message</span>
                            <textarea rows={5} id="message" {...register('message')}></textarea>
                            {errors.message && <span className="error">{errors.message.message}</span>}
                        </label>
                        <label className={`checkbox ${errors?.confirmed?.message && 'label-error'}`}>
                            <input type="checkbox" id="confirmed" {...register('confirmed')} /><span>I agree to the <Link target="_blank" to={'/terms'}>terms and Conditions</Link></span>
                            {errors.confirmed && <span className="error">{errors.confirmed.message}</span>}
                        </label>
                        <button className="brand" type="submit">Submit</button>
                    </form>
                </>
            }
            {
                !isLoading && hasSubmitted &&
                <h3>Thanks for contacting us, we will be in touch.</h3>
            }

        </section>
    )

}