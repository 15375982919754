import { useContext, useEffect } from 'react';
import './Carousel.scss';
import { SearchContext } from '../../providers/SearchProvider';
import React from 'react';
import { Link } from 'react-router-dom';


export function Carousel() {

    const { isLoading, simpleSearch, simpleSearchData } = useContext(SearchContext);

    useEffect(() => {
        async function randomSearch() {
            const pageNum = Math.floor(Math.random() * 10) + 1;
            if (!simpleSearchData || !simpleSearchData.roles) {
                await simpleSearch(pageNum);
            }

        }

        randomSearch();
    }, []);

    const getName = (org) => {

        if (typeof org === 'string') {
            return org;
        } else if (typeof org === 'object' && org.name) {
            return org.name
        }
    }

    const getTitle = (title) => {
        if (title && title.length >= 30) {
            return title.substring(0, 30)
        }
        return title;
    }

    return (
        <section className='carousel'>
            <ul>
                {

                    simpleSearchData.roles &&
                        simpleSearchData.roles.map(role => {
                            return <li className='carousel__item' id={role._id}>
                                <span>
                                    <p>{getTitle(role.jobTitle)}</p>
                                    <p className='light'>{getName(role.hiringOrganization)}</p>
                                </span>
                                <Link className='brand' to={`/search/${role._id}/${role.jobTitle}`}>View</Link>
                            </li>
                        })
                    }
                {
                    isLoading &&
                    <>
                        <li className='carousel__item skeleton' id={1}>
                            <span>
                                <p></p>
                                <p className='light'></p>
                            </span>
                            <Link className='brand'></Link>
                        </li>

                        <li className='carousel__item skeleton' id={2}>
                            <span>
                                <p></p>
                                <p className='light'></p>
                            </span>
                            <Link className='brand'></Link>
                        </li>

                        <li className='carousel__item skeleton' id={3}>
                            <span>
                                <p></p>
                                <p className='light'></p>
                            </span>
                            <Link className='brand'></Link>
                        </li>
                    </>
                }



            </ul>
        </section>
    )



}