import { Link } from 'react-router-dom';
import './hero.css';

export function Hero({ isHome, children }) {

    return (
        <section className={`hero__large ${isHome ? 'hero__large--home' : ''}`}>
            <div className='hero__body'>
                <h1>
                Ey up! 👋  Welcome to Bronte Jobs
                </h1>
                <p>
                The best place in West Yorkshire for finding yer next job.  We're reet good at getting folk like you sorted with a proper job, whether it's full-time, part-time, or just a bit o' work on t' side.  Have a gander at what we've got - there's all sorts, so you're bound to find summat that takes yer fancy.
                </p>
                <Link to={'/search'} className='brand'>Have a Nosey</Link>
            </div>
            {children}
        </section>
    )

}