import { useEffect } from "react";
import { HeroNarrow } from "../../components/HeroNarrow/HeroNarrow";
import './Privacy.scss';
import { Helmet } from "react-helmet";

export function Privacy() {


    return (
        <>
            <Helmet>
                <title>Privacy Page - Bronte Jobs: Find Jobs in West Yorkshire | Keighley and Surrounding Areas Job Board</title>
                <meta name="description" content="Find your next job in West Yorkshire with Bronte Jobs. Browse the latest vacancies, full-time and part-time, from top employers in Keighley, Bradford, and beyond.  Start your job search today!" />
            </Helmet>
            <HeroNarrow isEmpty={true}></HeroNarrow>
            <section className="privacy">
                <h1>Privacy Policy</h1>
                <p>
                    This Privacy Policy applies to the website located at brontejobs.com (the “Site”). The Site does not collect any personal data from its users, including IP addresses and geolocations. However, the Site will collect user email addresses, provided the address has been volunteered by the user.
                    If you have any questions about this Privacy Policy, please contact us at <a href="mailto::info@brontejobs.com">info@brontejobs.com</a>.
                </p>
            </section>
        </>
    )
}