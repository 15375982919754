import { useEffect, useRef, useState } from "react";
import { HeroNarrow } from "../../components/HeroNarrow/HeroNarrow";
import { Link, useParams } from "react-router-dom";
import { ChevronLeft, Share } from '@mui/icons-material'
import { AvailableJobs } from "../../components/AvailableJobs/AvailableJobs";
import './ApplyRoles.scss';
import { StaticMap } from "../../components/StaticMap/StaticMap";
import { Helmet } from "react-helmet";

export function ApplyRole() {

    const [role, setRole] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [notFound, setIsNotFound] = useState(false);
    const { id } = useParams();
    const baseUrl = 'https://api.brontejobs.com/api/roles/search/';


    useEffect(() => {
        setIsNotFound(false)

        fetch(baseUrl + id).then(o => o.json()).then(resp => {
            resp = datafix(resp);
            setRole(resp);
            setIsLoading(false);
        }).catch(() => {
            setIsNotFound(true)
        })

    }, []);



    function extractLocation(model) {
        // Highest priority: jobLocation.address
        if (model.jobLocation && model.jobLocation.address && typeof model.jobLocation.address === 'string') {
            return model.jobLocation.address;
        }

        // Other potential location fields in your model
        // (Add or remove as needed based on your model structure)
        if (model.jobLocation && model.jobLocation.city) {
            return model.jobLocation.city;
        }
        if (model.jobLocation && model.jobLocation.state) {
            return model.jobLocation.state;
        }
        if (model.jobLocation && model.jobLocation.country) {
            return model.jobLocation.country;
        }
        if (model.user && model.user.location) {
            return model.user.location;
        }
        if (model.jobLocation && model.jobLocation.address && model.jobLocation.address.postalCode) {
            return model.jobLocation.address.postalCode;
        }
        if (model.company && model.company.headquarters) {
            return model.company.headquarters;
        }

        if (model.jobLocation && typeof model.jobLocation.address === 'object' && model.jobLocation.address.addressRegion) {
            return model.jobLocation.address.addressRegion;
        }

        if (model.jobLocation && model.jobLocation.addressLocality) {
            return model.jobLocation.addressLocality;
        }
        if (model.jobLocation && model.jobLocation.addressCountry) {
            return model.jobLocation.addressCountry;
        }

        // If all else fails
        return "Location unknown";
    }


    const datafix = (resp) => {

        try {
            if (resp.jobLocation && Array.isArray(resp.jobLocation)) {
                resp.jobLocation = resp.jobLocation[0];
            }

            if (resp.company && resp.hiringOrganization && resp.company === 'asda') {
                resp.hiringOrganization.logo = 'https://www.asda.jobs/image/776050/690a4525-b9c6-46be-8e1e-a50486acadc0/200/60'
            }

            return resp;
        }
        catch {
            console.warn('failed data fix');
            return resp;
        }
    }

    const getData = () => {

        if (!role.applicationData.startsWith('{')) {
            const pared = JSON.parse(role.applicationData);
            return pared;
        }
        return role.applicationData;
    }

    const onShare = () => {
        try {
            navigator.share({
                title: `${role.jobTitle}`,
                text: "from Bronte Jobs",
                url: window.location.href,
            });
        }
        catch {
            console.warn('share error')
        }
    }

    const getOrg = (role) => {
        return role.hiringOrganization?.name !== undefined ? role.hiringOrganization?.name : role.hiringOrganization
    }

    const getDescription = (role) => {
        if (role.description.includes('&lt')) {
            var doc = new DOMParser().parseFromString(role.description, "text/html");
            return doc.documentElement.textContent;
        }
        return role.description;
    }

    return (

        notFound ?
            <>
                <div className="apply_imageholder">
                    {role &&
                        <Helmet>
                            <title>Role Not Found | Bronte Jobs</title>
                            <meta name="description" content={`This role is no longer available. Browse current vacancies and apply online today`} />
                        </Helmet>
                    }
                    <HeroNarrow isEmpty={true}></HeroNarrow>
                </div>
                <section className="applyrole">

                    <div className="result title">
                        <h1 className="applyrole__title">
                            Role no longer available
                        </h1>
                    </div>
                </section>
            </>
            :
            <>
                <div className="apply_imageholder">
                    {role &&
                        <Helmet>
                            <title>{role.jobTitle} | Bronte Jobs</title>
                            <meta name="description" content={`Find the latest ${role.jobTitle} jobs in Bradford on Bronte Jobs. Browse current vacancies and apply online today`} />
                        </Helmet>
                    }

                    {role && role.location.coordinates ?
                        <StaticMap lat={role.location.coordinates[0]} lng={role.location.coordinates[1]}></StaticMap>
                        :
                        <HeroNarrow isEmpty={true}></HeroNarrow>
                    }
                </div>
                <section className="applyrole">
                    <AvailableJobs isLoading={isLoading} loaderOnly={true}></AvailableJobs>


                    {role &&
                        <>
                            <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: getData() }} ></script>
                            <div className="applyrole__back">
                                <Link to={-1}>
                                    <ChevronLeft></ChevronLeft>Back
                                </Link>
                            </div>

                            <div className="result title">
                                <h1 className="applyrole__title">
                                    {role.jobTitle}
                                </h1>
                            </div>

                            <div className="result applyrole__grid">
                                <div className="applyrole__points">
                                    {role.hiringOrganization.logo &&
                                        <div className="points__item points__item--image">
                                            <img height={40} src={role.hiringOrganization.logo} />
                                        </div>
                                    }
                                    <div className="points__item">
                                        <h2 className="points__header">Hiring company</h2>
                                        <p className="points__text">{getOrg(role)}</p>
                                    </div>
                                    <div className="points__item">
                                        <h2 className="points__header">Salary</h2>
                                        <p className="points__text">{role.baseSalaryValue !== undefined ? role.baseSalaryValue : 'N/A'}</p>
                                    </div>
                                    <div className="points__item">
                                        <h2 className="points__header">Employment Type</h2>
                                        <p className="points__text">{role.employmentType}</p>
                                    </div>
                                    <div className="points__item">
                                        <h2 className="points__header"> Location</h2>
                                        <p className="points__text">{extractLocation(role)}</p>
                                    </div>
                                </div>
                            </div>


                            <div className="points__item points__item--apply">
                                <button className="brand" onClick={() => { onShare() }}>
                                    <Share ></Share>
                                </button>
                                <Link className="brand" to={role.uniqueValue}>Apply Now</Link>
                            </div>

                            <div className="result applyrole__description" dangerouslySetInnerHTML={{ __html: getDescription(role) }}>
                            </div>
                        </>
                    }

                </section>
            </>
    )
}