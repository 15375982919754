import { useContext } from "react";
import { AvailableJobs } from '../../components/AvailableJobs/AvailableJobs';
import { Grid } from '../../components/Grid/Grid';
import { HeroNarrow } from '../../components/HeroNarrow/HeroNarrow';
import { Search } from '../../components/Search/Search';
import { JobList } from "../../components/JobList/JobList";
import { SearchContext } from "../../providers/SearchProvider";
import { Paginator } from "../../components/Paginator/Paginator";
import { Helmet } from "react-helmet";


export function RoleSearch() {

    const { searchResults } = useContext(SearchContext);


    const headerText = 'Snap Up Yer Dream Job Today!';
    const headerBody = `Don't faff about lookin' for jobs all over t' place!  Bronte Jobs has loads o' local job opportunities reet here in West Yorkshire.`;


    const subHeaderText = 'Still have questions?';
    const subHeaderBody = 'Sign up for job alerts and newsletters today!';
    const subLinkText = 'Contact us';
    const subLink = '/contact';

    const gridHeader = `Unlock Your Career Potential with Bronte Jobs`;
    const gridBody = `At Bronte Jobs, we provide valuable resources and expert advice to help you excel in your career. Whether you're looking for resume tips, interview guidance, or career advice, we've got you covered. Our team of professionals is dedicated to helping you unlock your full potential and land your dream job. Explore our wide range of services and take the next step towards a successful career.`;

    const subHeader2 = 'Stay Updated with Job Postings';
    const subHeader2Body = 'Sign up for job alerts and newsletters today!';


    return (
        <>
            <Helmet>
                <title>Search for jobs in West Yorkshire | Bronte Jobs</title>
                <meta name="description" content={`Find the latest jobs in Bradford on Bronte Jobs. Browse current vacancies and apply online today`} />
            </Helmet>
            <HeroNarrow body={headerBody} hideButton={true} header={headerText} align={'left'}></HeroNarrow>
            <Search ></Search>
            {(!searchResults || searchResults.length === 0) && <AvailableJobs loaderOnly={false}></AvailableJobs>}
            {searchResults?.length > 0 && <JobList jobs={searchResults}></JobList>}
            <Paginator></Paginator>
            <HeroNarrow body={subHeaderBody} header={subHeaderText} link={subLink} linkText={subLinkText} align={'centre'} size={'small'}></HeroNarrow>
            <Grid header={gridHeader} body={gridBody}></Grid>
            <HeroNarrow body={subHeader2Body} header={subHeader2} link={subLink} linkText={subLinkText} align={'left'}></HeroNarrow>

        </>
    )
}