import { useContext } from "react";
import { Input } from "../Input/Input";
import './search.scss';

import { ResultsContext } from "../../providers/ResultsProvider";

export function Search() {

    const { handleKeywordChange, keyword, setKeyword } = useContext(ResultsContext);


    const onKeywordChange = (e) => {
        setKeyword(e.target.value);
    }

    const emptySearch = () => {
        setKeyword('');
        handleKeywordChange('');
    }


    const search = async (e) => {
        e.preventDefault();
        handleKeywordChange(keyword);
    }

    return (
        <form className="search" onSubmit={(e) => search(e)}>
            <div className="search__inner">
                <Input placeholder={'what yer after'} value={keyword} onChange={onKeywordChange}>
                </Input>
                <button type="button" onClick={emptySearch} className={`search__clear ${keyword.length > 0 ? '' : 'search__clear--hidden'}`}>Clear</button>
                <div className="f-r">
                    <button type="submit" className="brand">Crack On!</button>
                </div>
            </div>
        </form>

    )
}