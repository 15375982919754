import { useContext } from 'react';
import './Paginator.scss';
import { SearchContext } from '../../providers/SearchProvider';
import { ResultsContext } from '../../providers/ResultsProvider';



export function Paginator() {

    const { currentPage, pageCount } = useContext(SearchContext);
    const { handlePageNumberChange } = useContext(ResultsContext)

    const generateBlocks = () => {

        let blocks = [];
        if (pageCount >= 1) {

            if (currentPage - 1 && currentPage > 0) {
                blocks.push({
                    page: currentPage - 1,
                    currentPage: currentPage,
                    label: 'Back'
                });
            }


            // current
            blocks.push({
                page: currentPage,
                currentPage: currentPage,
                label: `${currentPage} of ${pageCount}`
            });


            // next apge
            if (currentPage + 1 <= pageCount) {
                blocks.push({
                    page: currentPage + 1,
                    currentPage: currentPage,
                    label: 'Next'
                });
            }
        }


        return blocks.map((block, i) => {
            return <li key={block.page + block.label}  >
                <button  onClick={async(e) => { await handlePageNumberChange(e,block.page) }} className={`${block.currentPage === block.page ? 'current' : ''}`}>
                    {block.label}
                </button>
            </li>
        })
    }

    return (
        pageCount > 1 &&
        <ul className='paginator'>
            {generateBlocks()}
        </ul>
    )
}