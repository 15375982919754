
import { Link } from 'react-router-dom';
import './Footer.scss';
export function Footer() {
    return (

        <div className="footer">
            <hr></hr>
            <div className='footer__wrapper'>

                <section className='footer__inner'>
                    <Link to={'/'}>Discover</Link>
                    <Link to={'/search'}>Apply Now</Link>
                    <Link to={'contact'}>Contact Us</Link>
                    <Link to={'/privacy'}>Privacy Policy</Link>
                    <Link to={'/terms'}>Terms and Conditions</Link>
                </section>

                <section className='footer__inner'>

                    <span>
                        Bronte Jobs
                    </span>
                    <span>
                        &copy;&nbsp;2024
                    </span>
                </section>
            </div>
        </div>
    )
}