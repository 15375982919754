import { Link } from 'react-router-dom';
import './JobList.scss';


export function JobList({ jobs }) {

    const getExpiry = (date) => {
        const diffTime = Math.abs(new Date(date) - new Date());
        const dayDiff = Math.round(diffTime / (1000 * 60 * 60 * 24));

        if (dayDiff === 0) {
            return 'Today';
        }
        else if (dayDiff === 1) {
            return `in ${dayDiff} day`
        }
        if (isNaN(dayDiff)) {
            return 'soon';
        }
        return `in ${dayDiff} day's`;

    }

    function extractLocation(model) {
        // Highest priority: jobLocation.address
        if (model.jobLocation && model.jobLocation.address && typeof model.jobLocation.address === 'string') {
            return model.jobLocation.address;
        }

        // Other potential location fields in your model
        // (Add or remove as needed based on your model structure)
        if (model.jobLocation && model.jobLocation.city) {
            return model.jobLocation.city;
        }
        if (model.jobLocation && model.jobLocation.country) {
            return model.jobLocation.country;
        }
        if (model.jobLocation && model.jobLocation.state) {
            return model.jobLocation.state;
        }
        if (model.jobLocation && model.jobLocation.address && model.jobLocation.address.postalCode) {
            return model.jobLocation.address.postalCode;
        }
        if (model.user && model.user.location) {
            return model.user.location;
        }
        if (model.company && model.company.headquarters) {
            return model.company.headquarters;
        }

        if (model.jobLocation && typeof model.jobLocation.address === 'object' && model.jobLocation.address.addressRegion) {
            return model.jobLocation.address.addressRegion;
        }

        if (model.jobLocation && model.jobLocation.addressLocality) {
            return model.jobLocation.addressLocality;
        }
        if (model.jobLocation && model.jobLocation.addressCountry) {
            return model.jobLocation.addressCountry;
        }

        // If all else fails
        return "Location unknown";
    }


    function getTitle(job) {
        const hire = job?.hiringOrganization?.name ? job?.hiringOrganization?.name : job?.hiringOrganization ? job?.hiringOrganization : ''

        if (hire.length > 0) {
            return `${job.jobTitle} - ${hire}`
        }

        return job.jobTitle
    }

    return (
        <section className='job__list' id='job__list'>
            <ul>

                {
                    jobs.map(job => {
                        return (
                            <li className='job__item' key={job._id}>
                                <div className='job__row'>
                                    <h3 className='job__title'>{getTitle(job)}</h3>
                                    <span className='job__date'>Expires {getExpiry(job.validThrough)}</span>
                                </div>
                                {
                                    (job?.baseSalaryValue && job?.baseSalaryValue !== '0') &&
                                    <h4 className='job__salary'>{job?.baseSalaryValue}</h4>
                                }

                                <div className='job__info'>
                                    <div className='job__location'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                                            <path d="M16.5001 19.1667C19.4415 19.1667 21.8335 16.7747 21.8335 13.8333C21.8335 10.892 19.4415 8.49999 16.5001 8.49999C13.5588 8.49999 11.1668 10.892 11.1668 13.8333C11.1668 16.7747 13.5588 19.1667 16.5001 19.1667ZM16.5001 11.1667C17.9708 11.1667 19.1668 12.3627 19.1668 13.8333C19.1668 15.304 17.9708 16.5 16.5001 16.5C15.0295 16.5 13.8335 15.304 13.8335 13.8333C13.8335 12.3627 15.0295 11.1667 16.5001 11.1667ZM15.7268 29.5853C15.9525 29.7465 16.2229 29.8331 16.5002 29.8331C16.7775 29.8331 17.0478 29.7465 17.2735 29.5853C17.6788 29.2987 27.2055 22.42 27.1668 13.8333C27.1668 7.95199 22.3815 3.16666 16.5002 3.16666C10.6188 3.16666 5.83349 7.95199 5.83349 13.8267C5.79482 22.42 15.3215 29.2987 15.7268 29.5853ZM16.5002 5.83332C20.9122 5.83332 24.5002 9.42132 24.5002 13.84C24.5282 19.7573 18.6495 25.0707 16.5002 26.8133C14.3522 25.0693 8.47216 19.7547 8.50016 13.8333C8.50016 9.42132 12.0882 5.83332 16.5002 5.83332Z" fill="#222222" />
                                            <path d="M16.5001 19.1667C19.4415 19.1667 21.8335 16.7747 21.8335 13.8333C21.8335 10.892 19.4415 8.49999 16.5001 8.49999C13.5588 8.49999 11.1668 10.892 11.1668 13.8333C11.1668 16.7747 13.5588 19.1667 16.5001 19.1667ZM16.5001 11.1667C17.9708 11.1667 19.1668 12.3627 19.1668 13.8333C19.1668 15.304 17.9708 16.5 16.5001 16.5C15.0295 16.5 13.8335 15.304 13.8335 13.8333C13.8335 12.3627 15.0295 11.1667 16.5001 11.1667ZM15.7268 29.5853C15.9525 29.7465 16.2229 29.8331 16.5002 29.8331C16.7775 29.8331 17.0478 29.7465 17.2735 29.5853C17.6788 29.2987 27.2055 22.42 27.1668 13.8333C27.1668 7.95199 22.3815 3.16666 16.5002 3.16666C10.6188 3.16666 5.83349 7.95199 5.83349 13.8267C5.79482 22.42 15.3215 29.2987 15.7268 29.5853ZM16.5002 5.83332C20.9122 5.83332 24.5002 9.42132 24.5002 13.84C24.5282 19.7573 18.6495 25.0707 16.5002 26.8133C14.3522 25.0693 8.47216 19.7547 8.50016 13.8333C8.50016 9.42132 12.0882 5.83332 16.5002 5.83332Z" fill="#222222" />
                                        </svg>
                                        <span>
                                            {
                                                extractLocation(job)

                                            }
                                        </span>
                                    </div>
                                    <div className='job__location'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                                            <path d="M16.5 3.16669C9.14796 3.16669 3.16663 9.14802 3.16663 16.5C3.16663 23.852 9.14796 29.8334 16.5 29.8334C23.852 29.8334 29.8333 23.852 29.8333 16.5C29.8333 9.14802 23.852 3.16669 16.5 3.16669ZM16.5 27.1667C10.6186 27.1667 5.83329 22.3814 5.83329 16.5C5.83329 10.6187 10.6186 5.83335 16.5 5.83335C22.3813 5.83335 27.1666 10.6187 27.1666 16.5C27.1666 22.3814 22.3813 27.1667 16.5 27.1667ZM17.8333 9.83335H15.1666V17.052L19.5573 21.4427L21.4426 19.5574L17.8333 15.948V9.83335Z" fill="#222222" />
                                            <path d="M16.5 3.16669C9.14796 3.16669 3.16663 9.14802 3.16663 16.5C3.16663 23.852 9.14796 29.8334 16.5 29.8334C23.852 29.8334 29.8333 23.852 29.8333 16.5C29.8333 9.14802 23.852 3.16669 16.5 3.16669ZM16.5 27.1667C10.6186 27.1667 5.83329 22.3814 5.83329 16.5C5.83329 10.6187 10.6186 5.83335 16.5 5.83335C22.3813 5.83335 27.1666 10.6187 27.1666 16.5C27.1666 22.3814 22.3813 27.1667 16.5 27.1667ZM17.8333 9.83335H15.1666V17.052L19.5573 21.4427L21.4426 19.5574L17.8333 15.948V9.83335Z" fill="#222222" />
                                        </svg>
                                        <span>
                                            Full-time
                                        </span>
                                    </div>
                                    <div className='job__location job__location--apply'>
                                        <Link className='brand__alternative' to={`/search/${job._id}/${job.jobTitle}`}>View</Link>
                                    </div>
                                </div>

                            </li>
                        )
                    })
                }
            </ul>
        </section>

    )

}