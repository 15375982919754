import { ContactForm } from "../../components/ContactForm/ContactForm";
import { FAQList } from "../../components/FAQList/FAQList";
import { HeroNarrow } from "../../components/HeroNarrow/HeroNarrow";
import './Contact.scss';
import { Helmet } from "react-helmet";

export function Contact() {


    return (
        <>
            <Helmet>
                <title>Contact Us - Bronte Jobs: Find Jobs in West Yorkshire | Keighley and Surrounding Areas Job Board</title>
                <meta name="description" content="Find your next job in West Yorkshire with Bronte Jobs. Browse the latest vacancies, full-time and part-time, from top employers in Keighley, Bradford, and beyond.  Start your job search today!" />
            </Helmet>
            <HeroNarrow align={'left'} hideButton={true} header={'Contact Us'} body={`Now then! Welcome to our Contact-us page.  We're proper keen to hear your thoughts and we'll do our best to give you a hand`}></HeroNarrow>
            <section className="contact">
                <ContactForm></ContactForm>
                <FAQList></FAQList>
            </section>
        </>
    )
}