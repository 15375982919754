import { NavLink } from 'react-router-dom';
import './navbar.scss';
import { useState } from 'react';


export function Navbar() {

    const [isActive, setIsActive] = useState(false);
    return (
        <>
            <header className='navbar'>
                <button class="burger-button" aria-label="Toggle Menu" onClick={() => { setIsActive(!isActive) }}>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
                <div className={`navbar__links ${isActive ? 'active' : ''}`}>
                    <NavLink onClick={() => { setIsActive(false) }} activeClassName="active" to='/'>Ey up</NavLink>
                    <NavLink onClick={() => { setIsActive(false) }} activeClassName="active" to='/search'>Have a Gander</NavLink>
                    <NavLink onClick={() => { setIsActive(false) }} activeClassName="active" to="/contact">Give Us a Shout</NavLink>
                </div>
                <div className='navbar__logo'>
                    Bronte Jobs
                </div>
            </header>
            <header className='navbar__mobile'>
                
                <NavLink onClick={() => { setIsActive(false) }} activeClassName="active" to='/'>
                <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 0 24 24" width="40px" fill="#fff"><path d="M0 0h24v24H0z" fill="none"/><path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"/></svg>
                </NavLink>
                <NavLink onClick={() => { setIsActive(false) }} activeClassName="active" to='/search'>
                <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 0 24 24" width="40px" fill="#fff"><path d="M0 0h24v24H0z" fill="none"/><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/></svg>
                </NavLink>
                <NavLink onClick={() => { setIsActive(false) }} activeClassName="active" to="/contact">
                <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 0 24 24" width="40px" fill="#fff"><path d="M0 0h24v24H0z" fill="none"/><path d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56-.35-.12-.74-.03-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z"/></svg>
                </NavLink>
            </header>
        </>
    )
}